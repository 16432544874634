exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-authors-tsx": () => import("./../../../src/pages/authors.tsx" /* webpackChunkName: "component---src-pages-authors-tsx" */),
  "component---src-pages-clients-corporate-treasurers-tsx": () => import("./../../../src/pages/clients/corporate-treasurers.tsx" /* webpackChunkName: "component---src-pages-clients-corporate-treasurers-tsx" */),
  "component---src-pages-clients-institutions-tsx": () => import("./../../../src/pages/clients/institutions.tsx" /* webpackChunkName: "component---src-pages-clients-institutions-tsx" */),
  "component---src-pages-clients-strategic-partners-tsx": () => import("./../../../src/pages/clients/strategic-partners.tsx" /* webpackChunkName: "component---src-pages-clients-strategic-partners-tsx" */),
  "component---src-pages-company-about-us-tsx": () => import("./../../../src/pages/company/about-us.tsx" /* webpackChunkName: "component---src-pages-company-about-us-tsx" */),
  "component---src-pages-company-advisory-board-tsx": () => import("./../../../src/pages/company/advisory-board.tsx" /* webpackChunkName: "component---src-pages-company-advisory-board-tsx" */),
  "component---src-pages-company-careers-tsx": () => import("./../../../src/pages/company/careers.tsx" /* webpackChunkName: "component---src-pages-company-careers-tsx" */),
  "component---src-pages-company-leadership-team-tsx": () => import("./../../../src/pages/company/leadership-team.tsx" /* webpackChunkName: "component---src-pages-company-leadership-team-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-product-best-execution-tsx": () => import("./../../../src/pages/product/best-execution.tsx" /* webpackChunkName: "component---src-pages-product-best-execution-tsx" */),
  "component---src-pages-product-fx-multi-bank-platform-tsx": () => import("./../../../src/pages/product/fx-multi-bank-platform.tsx" /* webpackChunkName: "component---src-pages-product-fx-multi-bank-platform-tsx" */),
  "component---src-pages-product-margin-free-hedging-solution-tsx": () => import("./../../../src/pages/product/margin-free-hedging-solution.tsx" /* webpackChunkName: "component---src-pages-product-margin-free-hedging-solution-tsx" */),
  "component---src-pages-product-outsourced-execution-services-tsx": () => import("./../../../src/pages/product/outsourced-execution-services.tsx" /* webpackChunkName: "component---src-pages-product-outsourced-execution-services-tsx" */),
  "component---src-pages-product-transaction-cost-analysis-tsx": () => import("./../../../src/pages/product/transaction-cost-analysis.tsx" /* webpackChunkName: "component---src-pages-product-transaction-cost-analysis-tsx" */),
  "component---src-pages-resources-blog-tsx": () => import("./../../../src/pages/resources/blog.tsx" /* webpackChunkName: "component---src-pages-resources-blog-tsx" */),
  "component---src-pages-resources-glossary-tsx": () => import("./../../../src/pages/resources/glossary.tsx" /* webpackChunkName: "component---src-pages-resources-glossary-tsx" */),
  "component---src-pages-resources-insight-tsx": () => import("./../../../src/pages/resources/insight.tsx" /* webpackChunkName: "component---src-pages-resources-insight-tsx" */),
  "component---src-templates-advisory-page-tsx": () => import("./../../../src/templates/advisory-page.tsx" /* webpackChunkName: "component---src-templates-advisory-page-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-form-page-tsx": () => import("./../../../src/templates/form-page.tsx" /* webpackChunkName: "component---src-templates-form-page-tsx" */),
  "component---src-templates-forms-tsx": () => import("./../../../src/templates/forms.tsx" /* webpackChunkName: "component---src-templates-forms-tsx" */),
  "component---src-templates-glossary-page-tsx": () => import("./../../../src/templates/glossary-page.tsx" /* webpackChunkName: "component---src-templates-glossary-page-tsx" */),
  "component---src-templates-insight-page-tsx": () => import("./../../../src/templates/insight-page.tsx" /* webpackChunkName: "component---src-templates-insight-page-tsx" */),
  "component---src-templates-leader-page-tsx": () => import("./../../../src/templates/leader-page.tsx" /* webpackChunkName: "component---src-templates-leader-page-tsx" */),
  "component---src-templates-policy-page-tsx": () => import("./../../../src/templates/policy-page.tsx" /* webpackChunkName: "component---src-templates-policy-page-tsx" */),
  "component---src-templates-questionnaire-page-tsx": () => import("./../../../src/templates/questionnaire-page.tsx" /* webpackChunkName: "component---src-templates-questionnaire-page-tsx" */),
  "component---src-templates-thanks-tsx": () => import("./../../../src/templates/thanks.tsx" /* webpackChunkName: "component---src-templates-thanks-tsx" */)
}

